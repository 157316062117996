<template>
  <transition name="slide-fade-enter" appear>
    <div class="display-area py-3">
      <b-link v-if="!fatalError && incomingAuthenticationRequestId" @click="returnToApp"
        variant="outline-primary" class="ml-2 btn-sm" style="text-decoration: none"><fa icon="arrow-left" /> Return to app</b-link>
      <div class="display-area px-5 py-3">
        <div class="">
          <h3 class="d-flex align-items-center justify-content-center">
            <strong>Authress Federated Login</strong>
          </h3>
        </div>
        <template v-if="showNavigateToEmailToVerify">
          <br>
          <div class="d-flex justify-content-center">
            <span>Go to your email inbox to verify your new account.</span>
          </div>
          <br>
          <div class="d-flex justify-content-center">
            <email-linker v-if="emailInput" :email="emailInput" from-email-domain="authress.com" />
          </div>
        </template>

        <template v-else-if="fatalError">
          <div class="py-3 d-flex justify-content-center">
            <div style="max-width: 300px">
              <span class="text-danger">{{ fatalError }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <small>Trouble logging in? Please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
          </div>
        </template>

        <template v-else-if="flow === 'resetPassword'">
          {{ }}
        </template>

        <template v-else-if="flow === 'attachPasskey'">
          {{ }}
        </template>

        <template v-else-if="flow === 'verifyEmail'">
          <verify-email />
        </template>

        <template v-else>
          <div v-if="!signUp">
            <span>Select your existing account login method. Select Passkey to use your Passkey device to login.</span>
            <br><br>
            <div class="d-panel-body d-flex justify-content-center">
              <div class="d-flex align-items-center justify-content-center">
                <activity-button variant="outline-primary" class="backgroundFlare btn-sm mr-5" :action="() => tryWebAuthN()">
                  <div>
                    <img :src="passkeyLogo" alt="Passkey fingerprint" height="40" style="border-radius: 15%;">
                    <br>
                    <span>Passkey</span>
                  </div>
                </activity-button>

                <b-button variant="outline-primary" class="btn-sm" @click="showPasswordPrompt = true">
                  <div>
                    <img :src="passwordLogo" alt="Password lock" height="40" style="border-radius: 15%;">
                    <br>
                    <span>Password</span>
                  </div>
                </b-button>
              </div>
            </div>
          </div>

          <div v-if="signUp || showPasswordPrompt" class="d-panel-body d-flex justify-content-center">
            <div class="group" style="padding: 0">
              <b-form class="w-100" style="min-width: 150px" @submit.prevent>
                <b-form-group :state="displayErrors" class="mb-0">
                  <template #invalid-feedback>
                    <div v-if="validations.apiErrorInvalidInput === false && showPasswordPrompt">
                      <span class="text-danger">Login failed.</span>
                      <small class="text-primary"> Please enter a valid account username and password.</small>
                    </div>
                    <div v-else-if="validations.apiErrorInvalidInput === false && !showPasswordPrompt">
                      <span class="text-danger">Login failed.</span>
                      <small class="text-primary"> Please enter a valid account username.</small>
                    </div>
                    <div v-else-if="validations.apiErrorForbidden === false && showPasswordPrompt">
                      <span class="text-danger">Login failed.</span>
                      <small class="text-primary"> Your account could not be found or the password entered is not correct.</small>
                    </div>
                    <div v-else-if="validations.apiErrorForbidden === false && !showPasswordPrompt">
                      <span class="text-danger">Login failed.</span>
                      <small class="text-primary"> Your account could not be found.</small>
                    </div>
                    <div v-else-if="validations.emailInput === false">
                      <span class="text-danger">Email is required.</span>
                      <small v-if="signUp" class="text-primary"> Please select a secure email, you will use to identify your Authress account.</small>
                      <small v-else class="text-primary"> Enter your Authress account email, or press the Passkey button above to use your device's saved credentials.</small>
                    </div>
                    <div v-else-if="validations.passwordInput === false">
                      <span class="text-danger">Invalid password.</span>
                      <small class="text-primary"> Please enter a password that is at least 16 characters.</small>
                    </div>
                    <br>
                  </template>

                  <div class="d-flex flex-wrap justify-content-center">
                    <div>
                      <span v-if="signUp">Enter your preferred email:</span>
                      <span v-else>Sign in with email:</span>
                      <div>
                        <b-input-group class="flex-shrink-0 mb-2" style="width: 350px; max-width: 100%" :state="validations.emailInput">
                          <b-input-group-prepend is-text>
                            Email
                          </b-input-group-prepend>
                          <b-form-input autofocus autocomplete="on" name="email" id="email" type="email" :state="validations.emailInput"

                            v-model="emailInput" maxlength="64" required placeholder="me@domain.com"
                            @input="() => domainChanged()"
                            @keyup.enter="() => clickNextButton()" />
                        </b-input-group>

                        <template v-if="showPasswordPrompt">
                          <b-input-group class="flex-shrink-0 mb-2" style="width: 350px; max-width: 100%" :state="validations.passwordInput">
                            <b-input-group-prepend is-text>
                              Password
                            </b-input-group-prepend>
                            <b-form-input autocomplete="on" name="password" id="password" type="password" :state="validations.passwordInput"
                              v-model="passwordInput" maxlength="128" required placeholder="••••••••••••••••••••••••••••••••••••••••"
                              @input="() => { apiErrorForbidden = false; apiErrorInvalidInput = false; }"
                              @keyup.enter="() => clickNextButton()" />
                          </b-input-group>
                        </template>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <div class="mt-3 mb-2 mb-md-0 d-flex justify-content-center">
                  <template v-if="signUp">
                    <activity-button ref="nextButton" variant="outline-primary" class="btn-sm" :disabled="disableNextButton" :action="() => tryShowLoginMethodSelectorPopup()">
                      Sign up
                    </activity-button>
                  </template>
                  <template v-else>
                    <activity-button ref="nextButton" variant="outline-primary" class="btn-sm" :disabled="disableNextButton" :action="() => validateEmailAndAcceptLoginAttempt()">
                      Log in
                    </activity-button>
                  </template>
                </div>
              </b-form>
            </div>
          </div>

          <hr style="max-width: 50%; border-top: 1px solid">
          <template v-if="loginError">
            <div class="d-flex justify-content-center">
              <span class="text-danger">{{ loginError }}</span>
            </div>
            <br>
          </template>

          <div>
            <div class="d-flex justify-content-center">
              <div class="d-flex justify-content-center">
                <div v-if="!signUp"><b-button @click="signUp = true; showPasswordPrompt = false; passwordInput = null; showValidations = false;" class="btn-sm" variant="outline-primary">
                  Create account instead</b-button>
                </div>
                <div v-else><b-button @click.prevent="signUp = false; showValidations = false;" class="btn-sm" variant="outline-primary">Sign in instead</b-button></div>
              </div>
              <br>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <small>Trouble logging in? Please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
          </div>

          <b-modal v-model="showLoginMethodSelectorPopup" centered scrollable no-stacking size="lg" hide-header-close>
            <template #modal-title>
            Create your Authress account
          </template>
          <div class="d-panel-body">
            <div class="group">
              <h5>Step 2. Which login method would you like to use?</h5>
              <span>By default, Authress recommends <strong>Passkey</strong> authentication.</span><br><br>
              Do you want to use your Passkey device or create a password?
              <br><br>

              <div class="d-flex align-items-center justify-content-center">
                <activity-button variant="primary" class="backgroundFlare btn-sm mr-5" :action="() => registerViaWebAuthN()">
                  <div>
                    <img :src="passkeyLogo" alt="Passkey fingerprint" height="40" style="border-radius: 15%;">
                    <br>
                    <span>Passkey</span>
                  </div>
                </activity-button>

                <activity-button variant="primary" class="btn-sm" :action="() => { showPasswordPrompt = true; showValidations = false; showLoginMethodSelectorPopup = false; }">
                  <div>
                    <img :src="passwordLogo" alt="Password lock" height="40" style="border-radius: 15%;">
                    <br>
                    <span>Password</span>
                  </div>
                </activity-button>
              </div>
            </div>
          </div>
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button variant="outline-primary" @click="showLoginMethodSelectorPopup = false" class="mr-1">Go back</b-button>
            </div>
          </template>
          </b-modal>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import { BLink, BForm, BInputGroup, BInputGroupPrepend, BModal, BButton, BFormInput, BFormGroup } from 'bootstrap-vue';
import 'email-linker';

import jwtManager from '../util/jwtManager.js';
import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';
import passkeyLogo from './webauthn/passkey.png';
import passwordLogo from './webauthn/padlock.png';

import ActivityButton from '../components/activityButton.vue';
import VerifyEmail from './flows/verifyEmail.vue';

const federationHttpClient = new HttpClient('login.authress.com');
export default {
  name: 'FederationComponent',

  components: { BLink, BForm, BModal, BInputGroup, BInputGroupPrepend, BButton, BFormInput, BFormGroup, ActivityButton, VerifyEmail },

  data() {
    return {
      passkeyLogo,
      passwordLogo,
      host: 'authress.com',
      loading: true,
      abortController: null,

      incomingAuthenticationRequestId: null,
      signUp: false,
      showLoginMethodSelectorPopup: false,
      showPasswordPrompt: false,
      showNavigateToEmailToVerify: false,
      authenticationRequestId: null,
      emailInput: null,
      passwordInput: null,
      loginError: null,
      fatalError: null,
      apiErrorForbidden: null,
      apiErrorInvalidInput: null,
      showValidations: false
    };
  },

  computed: {
    flow() {
      return this.$route.query.flow;
    },
    hasErrors() {
      return Object.values(this.validations).some(v => !v && v !== undefined);
    },
    displayErrors() {
      return !this.showValidations ? null : !this.hasErrors;
    },
    disableNextButton() {
      return this.hasErrors;
    },
    validations() {
      if (!this.showValidations) {
        return {};
      }

      return {
        emailInput: !!(this.emailInput?.match('@') && this.emailInput?.length > 5 && this.emailInput?.length < 128) && !this.apiErrorForbidden && !this.apiErrorInvalidInput,
        passwordInput: this.showPasswordPrompt ? this.passwordInput?.length > 16 && !this.apiErrorForbidden && !this.apiErrorInvalidInput : !this.apiErrorForbidden && !this.apiErrorInvalidInput,
        apiErrorForbidden: !this.apiErrorForbidden,
        apiErrorInvalidInput: !this.apiErrorInvalidInput
      };
    }
  },

  created() {
    this.authenticationRequestId = this.$route.query.request_uri || this.$route.query.state;
    this.incomingAuthenticationRequestId = this.authenticationRequestId;

    // On verify email just send the user to verify email display page, don't try to do anything else here.
    if (this.flow === 'verifyEmail') {
      return;
    }
    this.tryWebAuthN(true);
  },

  methods: {
    async tryWebAuthN(conditionalLoginRequested) {
      this.showPasswordPrompt = false;
      this.apiErrorForbidden = false;
      this.apiErrorInvalidInput = false;

      if (!this.authenticationRequestId) {
        await this.generateAuthenticationRequestId();
      }

      if (!this.authenticationRequestId) {
        logger.warn({ title: 'Not executing webauthn for Authress Federation because no authentication request was created this attempt' });
        return;
      }

      const publicKeyCredentialRequestOptions = {
        challenge: Uint8Array.from(this.authenticationRequestId, c => c.charCodeAt(0)),
        // https://www.w3.org/TR/webauthn-3/#enumdef-userverificationrequirement
        // This is "required" so that if someone randomly finds a passkey out in the world, they cannot just get onto the account without providing that they know what the pin is. But if the device doesn't support it, whatever
        userVerification: 'preferred',
        timeout: 60000
      };

      if (conditionalLoginRequested) {
        // * If we can't use the conditional login, then just return, it doesn't make sense to force them to see the widget until they ask for it.
        const isConditionalMediationAvailable = await window?.PublicKeyCredential?.isConditionalMediationAvailable?.();
        if (!isConditionalMediationAvailable) {
          logger.log({ title: 'This device/browser does not support conditional mediation' });
          return;
        }

        logger.log({ title: 'This device/browser supports conditional mediation, automatically popping up login for existing credentials for Authress Federation.' });
      }

      if (this.abortController) {
        const abortError = new Error('UserRetriedPassKeyLoginSoForcingPreviousTryAbort');
        abortError.name = 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort';
        await this.abortController.abort(abortError);
      }

      try {
        this.abortController = new AbortController();
        const assertion = await navigator.credentials.get({
          // https://github.com/w3c/webauthn/wiki/Explainer:-WebAuthn-Conditional-UI
          // If the user already has credentials and their browser supports conditional mediation, then attempt to get those credentials
          mediation: conditionalLoginRequested ? 'conditional' : 'silent',
          publicKey: publicKeyCredentialRequestOptions,
          signal: this.abortController.signal
        });

        // For conditional mediation, we will never get here (that's just how the spec works)
        // * For non-conditional we can get here if there aren't any credentials saved, and the browser does something weird, realistically we should always fall into the catch block below, but some devices this might just not work.
        if (!assertion) {
          logger.error({ title: 'WebAuthN - there are no assertions associated with this agent, this action does not work for the user, what kind of device caused this problem?',
            publicKeyCredentialRequestOptions, authenticationRequestId: this.authenticationRequestId });
          this.loginError = 'You do no have an Authress account associated with your device.';
          return;
        }

        this.loginError = null;

        const webAuthNTokenRequest = {
          authenticatorAttachment: assertion.authenticatorAttachment,
          credentialId: assertion.id,
          type: assertion.type,
          userId: String.fromCharCode(...new Uint8Array(assertion.response.userHandle)),
          signature: btoa(String.fromCharCode(...new Uint8Array(assertion.response.signature))),
          authenticator: btoa(String.fromCharCode(...new Uint8Array(assertion.response.authenticatorData))),
          client: btoa(String.fromCharCode(...new Uint8Array(assertion.response.clientDataJSON)))
        };

        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ credentialId: assertion.id });
        const loginResponse = await federationHttpClient.patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`,
          { antiAbuseHash, loginType: 'WebAuthN', code: webAuthNTokenRequest });
        this.authenticationRequestId = null;
        if (loginResponse.data.requiresEmailVerification) {
          this.showNavigateToEmailToVerify = true;
          return;
        }
        if (loginResponse.data.redirectUrl) {
          window.location.replace(loginResponse.data.redirectUrl);
        }
      } catch (error) {
        if (error.status === 404) {
          logger.log({ title: 'Old login session attempted to be used', error, authenticationRequestId: this.authenticationRequestId });
          this.fatalError = 'Your login session has expired and can no longer be used to authenticate.';
          return;
        }
        if (error.name === 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort') {
          logger.log({ title: 'User is forcing retry of webauthn', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }
        if (error.name === 'NotAllowedError' || error.name === 'AbortError' || error.message === 'The operation either timed out or was not allowed.') {
          logger.info({ title: 'WebAuthN login failed due to abort or not allowed', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }
        if (error.message?.match('A request is already running')) {
          logger.error({ title: 'WebAuthN login failed due to what is usually a localhost hot reload problem with the browser. When on localhost ignore.',
            error, publicKeyCredentialRequestOptions, authenticationRequestId: this.authenticationRequestId });
          return;
        }
        logger.error({ title: 'WebAuthN login failed, investigate and add explicit handling here.', error, publicKeyCredentialRequestOptions, authenticationRequestId: this.authenticationRequestId });
        this.loginError = 'Your device produced an invalid security signature, please try again.';
      }
    },

    async registerViaWebAuthN() {
      const email = this.emailInput || '';
      this.apiErrorForbidden = false;
      this.apiErrorInvalidInput = false;
      this.showValidations = true;
      if (this.hasErrors) {
        return;
      }

      if (!this.authenticationRequestId) {
        await this.generateAuthenticationRequestId();
      }

      if (!this.authenticationRequestId) {
        logger.warn({ title: 'Not executing webauthn for Authress Federation because no authentication request was created this attempt' });
        return;
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/CredentialsContainer/create
      const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from(this.authenticationRequestId, c => c.charCodeAt(0)),
        rp: {
          name: 'Authress'
          // id: 'login.authress.com'
        },
        user: {
          id: Uint8Array.from(email, c => c.charCodeAt(0)),
          name: email,
          displayName: email
        },
        // https://www.iana.org/assignments/cose/cose.xhtml#algorithms (Order Matters)
        pubKeyCredParams: [
          // These other ones are disabled in the library
          // { type: 'public-key', alg: -8 }, /* EdDSA */
          // { type: 'public-key', alg: -36 }, /* ES512 */
          // { type: 'public-key', alg: -35 }, /* ES384 */
          { type: 'public-key', alg: -7 }, /* ES256 */
          // { type: 'public-key', alg: -39 }, /* PS512 */
          // { type: 'public-key', alg: -38 }, /* PS384 */
          // { type: 'public-key', alg: -37 }, /* PS256 */
          // { type: 'public-key', alg: -259 }, /* RS512 */
          // { type: 'public-key', alg: -258 }, /* RS384 */
          { type: 'public-key', alg: -257 } /* RS256 */
        ],
        authenticatorSelection: {
          // We register the key because later we attempt to pull the handle back out. In order to not register it we would need to accept the handle as input
          residentKey: 'required',
          // https://www.w3.org/TR/webauthn-3/#enumdef-userverificationrequirement
          userVerification: 'discouraged'
        },
        timeout: 60000,
        attestation: 'direct'
      };

      if (this.abortController) {
        const abortError = new Error('UserRetriedPassKeyLoginSoForcingPreviousTryAbort');
        abortError.name = 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort';
        await this.abortController.abort(abortError);
      }
      this.abortController = new AbortController();

      try {
        const credential = await navigator.credentials.create({
          publicKey: publicKeyCredentialCreationOptions,
          signal: this.abortController.signal
        });

        if (!credential) {
          logger.error({ title: 'WebAuthN - credential failed to generate for this agent, this action does not work for the user',
            publicKeyCredentialCreationOptions, authenticationRequestId: this.authenticationRequestId });
          return;
        }

        const webAuthNTokenRequest = {
          authenticatorAttachment: credential.authenticatorAttachment,
          credentialId: credential.id,
          type: credential.type,
          userId: email,
          attestation: btoa(String.fromCharCode(...new Uint8Array(credential.response.attestationObject))),
          client: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON)))
        };

        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ credentialId: credential.id });
        const loginResponse = await federationHttpClient.patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`,
          { antiAbuseHash, loginType: 'WebAuthN', code: webAuthNTokenRequest });
        this.authenticationRequestId = null;
        if (loginResponse.data.requiresEmailVerification) {
          this.showNavigateToEmailToVerify = true;
          return;
        }
        if (loginResponse.data.redirectUrl) {
          window.location.replace(loginResponse.data.redirectUrl);
        }
      } catch (error) {
        if (error.status === 404) {
          logger.log({ title: 'Old login session attempted to be used', error, authenticationRequestId: this.authenticationRequestId });
          this.fatalError = 'Your login session has expired and can no longer be used to authenticate.';
          return;
        }
        if (error.name === 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort') {
          logger.log({ title: 'User is forcing retry of webauthn', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }
        logger.error({ title: 'WebAuthN registration failed. Investigate and add explicit handling here.', error, publicKeyCredentialCreationOptions, authenticationRequestId: this.authenticationRequestId });
      }
    },

    domainChanged() {
      this.showValidations = false;
      this.apiErrorForbidden = false;
      this.apiErrorInvalidInput = false;
    },
    clickNextButton() {
      this.$refs.nextButton.onClick();
    },
    async tryShowLoginMethodSelectorPopup() {
      this.showValidations = true;
      if (this.hasErrors) {
        return;
      }

      if (!this.passwordInput) {
        this.showLoginMethodSelectorPopup = true;
        return;
      }

      await this.validateEmailAndAcceptLoginAttempt();
    },
    async generateAuthenticationRequestId() {
      logger.track({ title: 'The authenticationRequestId was not specified, Just log the user into their account instead and give them their home screen. TODO: Implement the user screens.' });

      const applicationId = 'AUTHRESS_FEDERATION';
      const { codeChallenge } = await jwtManager.getAuthCodes();
      const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ applicationId });

      try {
        const redirectUrl = new URL(window.location.origin);
        redirectUrl.pathname = '/profile';
        const directFederatedSignInResponse = await federationHttpClient.post('/authentication', {
          antiAbuseHash,
          source: 'HOSTED_LOGIN',
          redirectUrl: redirectUrl.toString(),
          codeChallengeMethod: 'S256', codeChallenge,
          responseLocation: 'query', flowType: 'code',
          applicationId
        });
        this.authenticationRequestId = directFederatedSignInResponse.data.authenticationRequestId;
      } catch (error) {
        // TODO: create a display error;
        logger.error({ title: 'Failed to log user in to Authress when no application was configured, TODO: configure an error to display to the user here', error });
        return;
      }
    },
    async validateEmailAndAcceptLoginAttempt() {
      this.loading = true;
      this.apiErrorForbidden = false;
      this.apiErrorInvalidInput = false;
      this.showValidations = true;
      if (this.hasErrors) {
        return;
      }

      if (!this.authenticationRequestId) {
        await this.generateAuthenticationRequestId();
      }

      try {
        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ userId: this.emailInput, password: this.passwordInput });
        const loginResponse = await federationHttpClient.patch(`/authentication/${this.authenticationRequestId}`, {
          antiAbuseHash,
          loginType: 'Password',
          code: {
            userId: this.emailInput,
            password: this.passwordInput
          }
        });

        this.authenticationRequestId = null;
        if (loginResponse.data.requiresEmailVerification) {
          this.showNavigateToEmailToVerify = true;
          return;
        }
        if (loginResponse.data.redirectUrl) {
          window.location.replace(loginResponse.data.redirectUrl);
        }
      } catch (error) {
        if (error.status === 404) {
          logger.log({ title: 'Old login session attempted to be used', error, authenticationRequestId: this.authenticationRequestId });
          this.fatalError = 'Your login session has expired and can no longer be used to authenticate.';
          return;
        }
        if (error.data?.errorCode === 'Expired') {
          logger.error({ title: 'Found expired login request logging into federated Authress account', error });
          await this.returnToApp();
          return;
        }

        if (error.data?.errorCode === 'InvalidLoginRequest') {
          logger.log({ title: 'Invalid Login Request found', error, authenticationRequestId: this.authenticationRequestId });
          await this.returnToApp();
          return;
        }

        if (error.status === 400) {
          logger.log({ title: 'API Invalid Input error', error, authenticationRequestId: this.authenticationRequestId });
          this.apiErrorInvalidInput = true;
        }
        if (error.status === 403) {
          logger.log({ title: 'API Forbidden error', error, authenticationRequestId: this.authenticationRequestId });
          this.apiErrorForbidden = true;
        }

        logger.error({ title: 'Found issue logging into federated Authress account, investigate and add an explicit handling for this problem', error });
        this.fatalError = 'Your login session has expired and can no longer be used to authenticate.';
      }
    },
    async returnToApp() {
      this.loading = true;
      this.showValidations = false;
      if (this.incomingAuthenticationRequestId) {
        try {
          const authenticationRequest = await federationHttpClient.get(`/authentication/${encodeURIComponent(this.incomingAuthenticationRequestId)}`);
          if (authenticationRequest.data.redirectUrl) {
            logger.log({ title: 'Redirecting to app location via redirect', appLocation: authenticationRequest.data.redirectUrl, authenticationRequestId: this.incomingAuthenticationRequestId, currentAuthenticationRequestId: this.authenticationRequestId });
            window.location.replace(authenticationRequest.data.redirectUrl);
            return;
          }
        } catch (error) {
          if (error.status === 400 && error.data?.errorCode === 'ExpiredRequest') {
            logger.log({ title: 'Redirecting to app location via redirect from expired request', appLocation: error.data.redirectUrl, authenticationRequestId: this.incomingAuthenticationRequestId, currentAuthenticationRequestId: this.authenticationRequestId });
            if (error.data.redirectUrl) {
              window.location.replace(error.data.redirectUrl);
              return;
            }
          }
          if (error.status !== 404) {
            logger.error({ title: 'Retry navigate back to app failed for unknown reason',
              error, authenticationRequestId: this.incomingAuthenticationRequestId, currentAuthenticationRequestId: this.authenticationRequestId }, false);
          }
          logger.warn({ title: 'Retry navigate back to app failed because could not fetch the auth request',
            error, authenticationRequestId: this.incomingAuthenticationRequestId, currentAuthenticationRequestId: this.authenticationRequestId }, false);
        }
      }

      // Because we don't want to be an open redirector we can't redirect back anywhere, the last place they could go is to the main page, so send them there
      window.location.replace('https://authress.com');
    }
  }
};
</script>

<style scoped lang="scss">
.backgroundFlare {
  box-shadow: 0px 0px 40px 1px var(--primary);
}
.btn-primary {
  &:hover, &:not(:disabled):not(.disabled):active {
    color: var(--brand-color--fg);
  }
}
input::placeholder {
  opacity: 0.3;
}
</style>
