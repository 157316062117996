<template>
  <div v-if="error" class="col-12 col-lg-8 display-area p-5">
    <div v-if="error === 'MISSING_APPLICATION_ID'">
      <h2>Login incomplete</h2>
      <div>
        The OAuth <code>client_id</code> parameter is missing in the url.
        <br><br>
        To complete the login flow, add the missing the parameter and retry the login request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_APPLICATION'">
      <h2>Login incomplete</h2>
      <div>
        The specified application <code>{{ applicationId }}</code> is not enabled for login.
        <br><br>
        To complete the login flow, review the <code>client_id</code> associated with this request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_REDIRECT'">
      <h2>Login incomplete</h2>
      <div>
        The specified application <code>{{ applicationId }}</code> has not enabled the <code>redirect_uri</code>.
        <br><br>
        To complete the login flow, review the <code>redirect_uri</code> associated with this request: <code>{{ redirectUrl }}</code>.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_LOGIN_APP'">
      <h2>Login incomplete</h2>
      <div>
        Currently log in through extensions is not enabled. To enable the login app, follow the <a href="https://authress.io/app/#/setup?focus=extensions">Extension login configuration</a>
        instructions in the <a href="https://authress.io/app/#/setup?focus=extensions">Authress Management Portal</a>.
        <br><br>
        <div class="d-flex">
          <b-button @click="configureExtensionLogin" variant="outline-primary">Configure extension login</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'MISSING_CODE_CHALLENGE'">
      <h2>Login incomplete</h2>
      <div>
        The OAuth <code>code_challenge</code> parameter is missing in the url.
        <br><br>
        To complete the login flow, add the missing the parameter and retry the login request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else>
      An unknown error occurred while attempting to log in.
      <br><br>
      The login service generated the following error:<br>
      <pre><code>{{ error }}</code></pre>
      Please try again by navigating back to the app.
      <br><br>
      <div class="d-flex">
        <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
      </div>
      <br><br>
      <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
    </div>
  </div>

  <div v-else-if="isFederatedLoginUi">
    <federation />
  </div>

  <div v-else-if="loading" style="height: 100%">
    <loader pageLoader />
  </div>

  <div v-else>
    <transition name="slide-fade" appear>
      <login-form :domainConfiguration="domainConfiguration" />
    </transition>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';
import LoginForm from './loginForm/loginForm.vue';
import jwtManager from '../util/jwtManager.js';

import Federation from './federation';

export default {
  name: 'HomeScreen',

  components: {
    BButton, Federation, LoginForm
  },

  props: {
    authenticationRequestId: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      host: this.$store.getters.host,
      loading: true,

      domainConfiguration: {},
      error: null
    };
  },

  computed: {
    urlParameters() {
      const parameters = {};
      [...new URLSearchParams(window.location.hash.slice(1)).entries()].map(([k, v]) => {
        parameters[k] = v;
      });
      [...new URLSearchParams(window.location.search.slice(1)).entries()].map(([k, v]) => {
        parameters[k] = v;
      });
      return parameters;
    },
    connectionId() {
      return this.$route.query.connectionId;
    },
    applicationId() {
      return this.$route.query.applicationId || this.$route.query.client_id;
    },
    redirectUrl() {
      const referrer = (document.referrer || document.referer) ? new URL(document.referrer || document.referer) : undefined;
      return this.$route.query.redirect_uri || referrer?.toString();
    },
    isFederatedLoginUi() {
      return window.location.hostname === 'login.authress.com' || window.location.hostname === 'localhost' && new URLSearchParams(window.location.search).get('federation');
    }
  },
  async created() {
    // In some cases some providers don't have the ability to redirect the user to the correct /path, so instead we have to guess that they wanted these based on the url parameters
    if (this.isFederatedLoginUi) {
      return;
    }
    // Handles Extension log in. Extension redirect here by default because they are using the OAuth flow, which says directly navigate to the /authorize url (which in this case is this top level route)
    // * Then we have to handle the authentication directly
    if (this.applicationId || this.$route.query.code_challenge) {
      await this.extensionAuthentication();
      return;
    }

    const authenticationRequestId = this.authenticationRequestId || this.$route.query.request_uri;
    if (!authenticationRequestId) {
      const response = await new HttpClient().get('/authentication/configuration');
      this.domainConfiguration = response.data || {};
      this.loading = false;
      return;
    }

    logger.track({ title: 'There was a redirect to the managed login UI at the /root location with an authenticationRequestId set, is this even being used? This log message says yes!', authenticationRequestId });
    await new Promise(resolve => setTimeout(resolve, 300));

    this.$store.commit('setAuthenticationRequest', authenticationRequestId);

    try {
      const requestOptions = await new HttpClient().get(`/authentication/${encodeURIComponent(authenticationRequestId)}`);
      if (!requestOptions.data.authenticationUrl) {
        logger.error({ title: 'The authentication request did not return an authentication url', authenticationRequestId, data: requestOptions.data });
        await new Promise(resolve => setTimeout(resolve, 300));
      }
      window.location.replace(requestOptions.data.authenticationUrl);
      return;
    } catch (error) {
      this.loading = false;
      return;
    }
  },

  methods: {
    /**
     * Handles Extension log in. Extension redirect here by default because they are using the OAuth flow, which says directly navigate to the /authorize url (which in this case is this top level route). Then we have to handle the authentication directly
     */
    async extensionAuthentication() {
      if (!this.applicationId) {
        this.error = 'MISSING_APPLICATION_ID';
        return;
      }

      const codeChallenge = this.$route.query.code_challenge;
      if (!codeChallenge) {
        logger.track({ title: 'Use ended up at the main page without a code challenge, which means they did not come from the login SDK. If we do not have a code challenge we cannot continue because the API will reject those requests. We need to start displaying an error to the user here.', pageData: this.$data });
        return;
      }

      // If the user isn't logged in
      // * Generate a new authentication request
      // * Save it with the necessary info
      // * redirect them to the customers login portal

      // if the user is logged in generate the token and redirect them back to where they came from with a valid authorization code to exchange for a real token

      try {
        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ applicationId: this.applicationId });
        const authenticationRequest = await new HttpClient().post('/authentication', {
          antiAbuseHash,
          source: 'HOSTED_LOGIN',
          redirectUrl: this.redirectUrl,
          codeChallengeMethod: 'S256',
          codeChallenge,
          state: this.$route.query.state,
          requestedScopes: this.$route.query.scope,
          responseLocation: 'query',
          flowType: 'code',
          applicationId: this.applicationId
        });

        logger.track({ title: 'Redirecting the user after an authentication request was created, where are we redirecting them to? If we redirecting them to this same page, just handle the parameter changes appropriately, do not force refresh the page for no reason', authenticationRequest: authenticationRequest.data, location: window.location.href });
        window.location.assign(authenticationRequest.data.authenticationUrl);
      } catch (error) {
        if (error.data?.errorCode === 'InvalidApplication') {
          this.error = 'INVALID_APPLICATION';
          return;
        }

        if (error.data?.errorCode === 'InvalidRedirectUrl') {
          this.error = 'INVALID_REDIRECT';
          return;
        }

        if (error.data?.errorCode === 'MissingLoginApplication') {
          this.error = 'INVALID_LOGIN_APP';
          return;
        }

        if (error.data?.errorCode === 'InvalidRequest') {
          this.error = error.data.title;
          return;
        }

        this.error = 'An unknown error has occurred, please try again';
      }
    },

    returnToApp() {
      this.loading = true;
      this.error = null;

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.replace(appLocation);
    },

    configureExtensionLogin() {
      window.location.replace('https://authress.io/app/#/setup?focus=extensions');
    }
  }
};
</script>

<style scoped>
</style>
