<template>
  <div class="display-area">
    <div class="d-flex justify-content-center mb-2 flex-wrap">
      <h3 v-if="domainConfiguration.logo" class="d-flex justify-content-center w-100 mb-2">
        <img style="height: 36px; border-radius: 10px" :src="domainConfiguration.logo">
      </h3>
      <h3 class="d-flex justify-content-center w-100 mb-2"><span>{{ 'Reset your password' }}</span></h3>
      <small>Return to <strong class="support-link pointer" @click="$emit('input', false)">Sign in</strong></small>
    </div>

    <div class="my-3" v-if="!emailSent">
      <div class="text-primary my-3">First you will need to sign in via your email. Click below to receive an email, and from there you will be able to reset your password.</div>

      <div class="d-flex justify-content-center">
        <BFormGroup style="width: 300px; max-width: 95%; margin-bottom: 0;">
          <template #label><span style="font-size: 12px; padding-bottom: 0;">EMAIL</span></template>
          <BFormInput :disabled="true" class="flex-shrink-0" maxlength="100" style="width: 300px; max-width: 100%" required
            autofocus autocomplete="on" name="email" id="email" :value="email" />
        </BFormGroup>
      </div>

      <div class="d-flex justify-content-center my-2">
        <b-form-group style="width: 300px; max-width: 95%">
          <activity-button class="btn-sm branded-primary-button pointer" style="width: 100% !important;"
          :action="() => signInWithEmail()">
            <span>Sign in with email</span>
          </activity-button>
        </b-form-group>
      </div>
    </div>

    <div v-else class="d-flex flex-column align-items-center justify-content-center">
      <BFormGroup style="width: 300px; max-width: 95%; margin-bottom: 0;">
        <template #label><span style="font-size: 12px; padding-bottom: 0;">EMAIL</span></template>
        <BFormInput :disabled="true" class="flex-shrink-0" maxlength="100" style="width: 300px; max-width: 100%" required
          autofocus autocomplete="on" name="email" id="email" :value="email" />
      </BFormGroup>
      <br>
      <email-linker :email="email" :from-email-domain="host" />
    </div>
  </div>
</template>
  
<script>
import 'email-linker';

import { BFormInput, BFormGroup } from 'bootstrap-vue';
  
import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';
import jwtManager from '../../util/jwtManager.js';
  
import ActivityButton from '../../components/activityButton.vue';

export default {
  name: 'HostedScreen',
  
  components: {
    BFormInput, BFormGroup, ActivityButton
  },
  
  props: {
    domainConfiguration: {
      type: Object,
      required: false,
      default() { return {}; }
    },
    email: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  
  data() {
    return {
      host: this.$store.getters.host,

      emailSent: false
    };
  },

  computed: {
  },
  
  methods: {
    async signInWithEmail() {
      const parameters = {
        antiAbuseHash: await jwtManager.calculateAntiAbuseHash({ email: this.email }, { requiresExtendedMatch: true }),
        email: this.email,
        connectionId: 'password',
        loginType: 'PasswordReset'
      };

      try {
        await new HttpClient().patch(`/authentication/${this.authenticationRequestId}`, parameters);
      } catch (error) {
        logger.error({ title: '[LoginForm] Failed to start password reset frow', error, parameters });
      }

      // In any case, tell the user that it worked and send them to the email direct jump page
      this.emailSent = true;
    }
  }
};

</script>

<style scoped lang="scss">
.display-area {
  width: 400px;
  max-width: 100vw;
  max-height: 98vh;
}
</style>
