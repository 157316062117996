<template>
  <transition name="slide-fade-enter" appear>
    <div class="display-area p-5" style="width: 800px; max-width: 80vw;">
      <div class="">
        <h3 class="d-flex align-items-center justify-content-center">
          <strong>Login</strong>
        </h3>
      </div>
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group col-12 col-md-9 col-lg-7 col-xl-6" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <b-form-group :state="displayErrors">
              <template #invalid-feedback>
                <div v-if="!apiError">
                  <span class="text-danger">Email is required.</span>
                  <small class="text-primary"> If you are unsure of your domain, you can contact an account admin to help you.</small>
                </div>
                <div v-else-if="apiError === 'MissingRequiredProperty'">
                  A URL property required for login is not correctly specified.
                </div>
                <div v-else-if="apiError === 'InvalidApplication'">
                  Domain <strong>{{ specifiedDomain }}</strong> is not configured for SSO.
                </div>
                <div v-else-if="apiError === 'InvalidConnectionConfiguration'">
                  SSO Login is currently disabled. Please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a> for additional information.
                </div>
                <div v-else-if="apiError === 'SsoConnectionError'">
                  Failed to connect to the configured provider for <strong>{{ specifiedDomain }}</strong>.
                  <small class="text-danger"><br>Please try again and if you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
                </div>
              </template>
              <span>Enter your email:</span>
              <b-form-input autofocus autocomplete="on" name="email" id="email" :state="displayErrors"
                class="flex-shrink-0" style="width: 300px; max-width: 100%"
                v-model="emailInput" maxlength="32" required
                @input="() => domainChanged()"
                @keyup.enter="() => clickNextButton()" />
            </b-form-group>
          </div>

          <div class="d-flex justify-content-center mt-2">
            <b-button @click="returnToApp" variant="outline-primary" class="mr-2">Back</b-button>
            <activity-button ref="nextButton" variant="primary" :disabled="disableNextButton" :action="() => validateDomainAndRedirectToLogin()">Next</activity-button>
          </div>
          <br>

          <div class="d-flex justify-content-center mt-4">
            <small>Trouble logging in? Please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BButton, BFormInput, BFormGroup } from 'bootstrap-vue';

import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';
import jwtManager from '../util/jwtManager.js';

import ActivityButton from '../components/activityButton.vue';

export default {
  name: 'HostedScreen',

  components: { BButton, BFormInput, BFormGroup, ActivityButton },

  data() {
    return {
      host: this.$store.getters.host,
      loading: true,
      emailInput: null,
      apiError: null,
      showValidations: false
    };
  },

  computed: {
    authenticationRequestId() {
      return this.$route.query.state;
    },
    specifiedDomain() {
      if (this.emailInput?.match('@')) {
        return this.emailInput.split('@')[1];
      }
      return this.emailInput;
    },

    hasErrors() {
      return Object.values(this.validations).some(v => !v);
    },
    displayErrors() {
      return !this.showValidations ? null : !this.hasErrors;
    },
    disableNextButton() {
      return this.hasErrors && this.apiError !== 'SsoConnectionError';
    },
    validations() {
      if (!this.showValidations) {
        return {};
      }

      return {
        authenticationRequestId: !!this.authenticationRequestId,
        emailInput: !!this.emailInput,
        apiError: !this.apiError
      };
    }
  },

  created() {
    // This UI redirected to itself when there is no Custom Login set. Give the user an SSO Login experience
    logger.error({ title: 'This UI /hosted is not supposed to be in use anymore' });
  },

  methods: {
    domainChanged() {
      this.apiError = null;
    },
    clickNextButton() {
      this.$refs.nextButton.onClick();
    },
    async validateDomainAndRedirectToLogin() {
      this.loading = true;
      this.showValidations = true;
      this.apiError = this.authenticationRequestId ? null : 'MissingRequiredProperty';
      if (this.hasErrors) {
        return;
      }

      try {
        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ tenantLookupIdentifier: this.emailInput });
        const updatedAuthenticationRequest = await new HttpClient().patch(`/authentication/${this.authenticationRequestId}`, {
          antiAbuseHash,
          tenantLookupIdentifier: this.emailInput, source: 'HOSTED_LOGIN'
        });

        window.location.replace(updatedAuthenticationRequest.data.authenticationUrl);
      } catch (error) {
        if (error.data?.errorCode === 'InvalidConnection' || error.data?.errorCode === 'InvalidTenantIdentifier') {
          this.apiError = 'InvalidApplication';
          return;
        }
        if (error.data?.errorCode === 'InvalidConnectionConfiguration') {
          this.apiError = 'InvalidConnectionConfiguration';
          return;
        }
        if (error.data?.errorCode === 'InvalidLoginRequest') {
          await this.returnToApp();
          return;
        }
        logger.error({ title: 'Unexpected Error found when attempting to patch in the tenantLookupIdentifier for hosted extension login, showing an SSO connection error, but really the problem is something else. Investigate.', error });
        this.apiError = 'SsoConnectionError';
      }
    },
    async returnToApp() {
      this.loading = true;
      this.showValidations = false;
      this.apiError = null;
      if (this.authenticationRequestId) {
        try {
          const authenticationRequest = await new HttpClient().get(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`);
          if (authenticationRequest.data.redirectUrl) {
            logger.log({ title: 'Redirecting to app location via redirect', appLocation: authenticationRequest.data.redirectUrl });
            window.location.replace(authenticationRequest.data.redirectUrl);
            return;
          }
        } catch (error) {
          if (error.status === 400 && error.data?.errorCode === 'ExpiredRequest') {
            logger.log({ title: 'Redirecting to app location via redirect from expired request', appLocation: error.data.redirectUrl });
            if (error.data.redirectUrl) {
              window.location.replace(error.data.redirectUrl);
              return;
            }
          }
          logger.error({ title: 'Retry navigate back to app failed', error, loginResponse: this.authResponse }, false);
        }
      }

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.replace(appLocation);
    }
  }
};
</script>

<style scoped>

</style>
